import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { OrganizationService } from '../../components/organization';
import { LoadCustomers } from '../customer';
import { ClearLoadedEnvironments } from '../environments';
import { LoadLicences } from '../license';
import { addSnackbar } from '../snackbar';
import {
	CreateOrganization,
	CreateOrganizationFail,
	CreateOrganizationSuccess,
	DeleteOrganization,
	DeleteOrganizationFail,
	DeleteOrganizationSuccess,
	LoadOrganizations,
	LoadOrganizationsFail,
	LoadOrganizationsSuccess,
	OrganizationActions,
	OrganizationActionTypes,
	UpdateOrganization,
	UpdateOrganizationFail,
	UpdateOrganizationSuccess,
} from './organization.actions';

const loadOrganisation$ = (action$: ActionsObservable<OrganizationActions>) =>
	action$.pipe(
		ofType(OrganizationActionTypes.LoadOrganizations),
		switchMap(() =>
			OrganizationService.getAll().then(
				definitions => new LoadOrganizationsSuccess(definitions),
				err => new LoadOrganizationsFail(err),
			),
		),
	);

const createOrganization$ = (action$: ActionsObservable<CreateOrganization>) =>
	action$.pipe(
		ofType(OrganizationActionTypes.CreateOrganization),
		switchMap(action =>
			OrganizationService.create(action.name).then(
				def => new CreateOrganizationSuccess(def),
				err => new CreateOrganizationFail(err),
			),
		),
	);

const createOrganizationFail$ = (action$: ActionsObservable<CreateOrganizationFail>) =>
	action$.pipe(
		ofType<CreateOrganizationFail>(OrganizationActionTypes.CreateOrganizationFail),
		switchMap(async () =>
			addSnackbar({ variant: 'error', message: 'Failed to create organization' }),
		),
	);

const updateOrganization$ = (action$: ActionsObservable<UpdateOrganization>) =>
	action$.pipe(
		ofType(OrganizationActionTypes.UpdateOrganization),
		switchMap(action =>
			OrganizationService.update(action.organization).then(
				def => new UpdateOrganizationSuccess(def),
				err => new UpdateOrganizationFail(err),
			),
		),
	);

const updateOrganizationFail$ = (action$: ActionsObservable<UpdateOrganizationFail>) =>
	action$.pipe(
		ofType<UpdateOrganizationFail>(OrganizationActionTypes.UpdateOrganizationFail),
		switchMap(async () =>
			addSnackbar({ variant: 'error', message: 'Failed to update organization' }),
		),
	);

const deleteOrganization$ = (action$: ActionsObservable<DeleteOrganization>) =>
	action$.pipe(
		ofType(OrganizationActionTypes.DeleteOrganization),
		switchMap(action =>
			OrganizationService.deleteOrg(action.orgId, action.force).then(
				_ => new DeleteOrganizationSuccess(action.orgId),
				err => new DeleteOrganizationFail(err),
			),
		),
	);

const deleteOrgSuccess$ = (action$: ActionsObservable<DeleteOrganizationSuccess>) =>
	action$.pipe(
		ofType(OrganizationActionTypes.DeleteOrganizationSuccess),
		map(() => new LoadOrganizations()),
	);

const reloadCustomers$ = (
	action$: ActionsObservable<DeleteOrganizationSuccess | UpdateOrganizationSuccess>,
) =>
	action$.pipe(
		ofType(
			OrganizationActionTypes.DeleteOrganizationSuccess,
			OrganizationActionTypes.UpdateOrganizationSuccess,
		),
		map(() => new LoadCustomers()),
	);

const reloadLicenses$ = (
	action$: ActionsObservable<DeleteOrganizationSuccess | UpdateOrganizationSuccess>,
) =>
	action$.pipe(
		ofType(
			OrganizationActionTypes.DeleteOrganizationSuccess,
			OrganizationActionTypes.UpdateOrganizationSuccess,
		),
		map(() => new LoadLicences(JSON.parse(localStorage.getItem('license:showDisabled') ?? 'flse'))),
	);

const clearEnvironments$ = (
	action$: ActionsObservable<DeleteOrganizationSuccess | UpdateOrganizationSuccess>,
) =>
	action$.pipe(
		ofType(
			OrganizationActionTypes.DeleteOrganizationSuccess,
			OrganizationActionTypes.UpdateOrganizationSuccess,
		),
		map(() => new ClearLoadedEnvironments()),
	);

const deleteOrganizationFail$ = (action$: ActionsObservable<DeleteOrganizationFail>) =>
	action$.pipe(
		ofType<DeleteOrganizationFail>(OrganizationActionTypes.DeleteOrganizationFail),
		switchMap(async () =>
			addSnackbar({ variant: 'error', message: 'Failed to delete organization' }),
		),
	);
export const effects$ = combineEpics(
	loadOrganisation$,
	createOrganization$,
	deleteOrganization$,
	updateOrganization$,
	createOrganizationFail$,
	updateOrganizationFail$,
	deleteOrganizationFail$,
	deleteOrgSuccess$,
	reloadCustomers$,
	reloadLicenses$,
	clearEnvironments$,
);
